import React from "react";
import { Text, Container, Button } from "@atoms";
import { Card } from "@molecules";
import useArticlesData from "@staticQueries/ArticlesQuery";
import EntriesPatternLeft from "@svg/EntriesPatternLeft";
import EntriesPatternRight from "@svg/EntriesPatternRight";
import useCategoriesData from "@staticQueries/CategoriesQuery";
import clsx from "clsx";

const ColoredBackdrop = ({ colorScheme }) => {
  const patternColor = {
    yellowWhite: "text-white",
    purpleTeal: "text-teal",
    slate: "text-transparent",
  };

  const bgColor = {
    yellowWhite: "bg-yellow",
    purpleTeal: "bg-purple",
    slate: "bg-slate",
  };

  return (
    <div
      className={clsx(
        "absolute h-full w-full rounded-3xl rounded-br-5xl md:rounded-br-8xl",
        bgColor[colorScheme]
      )}
    >
      <EntriesPatternLeft
        className={clsx(
          "absolute -bottom-4 left-0 z-0 w-[27.5rem]",
          patternColor[colorScheme]
        )}
      />
      <EntriesPatternRight
        className={clsx(
          "absolute -right-24 -top-32 z-0 w-[27.5rem]",
          patternColor[colorScheme]
        )}
      />
    </div>
  );
};

const LatestEntries = ({
  heading,
  description,
  categories,
  button,
  colorScheme,
  entries,
  pull,
}) => {
  const { articleCategory } = useCategoriesData();
  const defaultCatUid = articleCategory.find(c => c.slug === "news").uid;
  const { articles } = useArticlesData();
  const hasCategory = cat => {
    const catUids = categories?.map(c => c?.uid);
    return catUids ? catUids.includes(cat.uid) : defaultCatUid === cat.uid;
  };
  const filteredArticles = articles
    ?.filter((article, i) => {
      return article?.articleCategory?.some(hasCategory);
    })
    ?.slice(0, 3)
    ?.map(article => {
      return {
        uid: article?.uid,
        image: article?.metaImage,
        title: article?.title,
        date: article?.postDate,
        link: article?.url,
        author:
          article?.articleAuthor?.[0]?.title ||
          "Constructive Dialogue Institute",
        categoryLabel: article.categoryLabel,
      };
    });
  const cards = entries?.length ? entries : filteredArticles;

  return (
    <section
      className={clsx({
        "my-10 sm:my-20": !pull,
        "z-50 -mt-28 mb-10 sm:-mt-36 sm:mb-20 lg:-mt-40": pull,
      })}
    >
      <Container smallerPadding>
        <div
          className={clsx(
            "relative flex flex-col overflow-hidden md:flex-row"
          )}
        >
          <ColoredBackdrop colorScheme={colorScheme || "yellowWhite"} />

          <Container
            variant="sm"
            className="relative z-10 py-10 text-center sm:pt-16"
          >
            <Text
              variant="h2"
              className={clsx({
                "text-purple": colorScheme === "yellowWhite" || !colorScheme,
                "text-white":
                  colorScheme === "purpleTeal" || colorScheme === "slate",
              })}
            >
              {heading || "In The News"}
            </Text>

            {description && (
              <Text
                variant="body"
                className={clsx("mx-auto mb-10 mt-6 max-w-2xl", {
                  "text-slate": colorScheme === "yellowWhite" || !colorScheme,
                  "text-white":
                    colorScheme === "purpleTeal" || colorScheme === "slate",
                })}
              >
                {description}
              </Text>
            )}

            {cards?.length > 0 && (
              <div className="relative z-10 my-8 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
                {cards?.map(entry => {
                  return <Card key={entry.uid} {...entry} className="h-full" />;
                })}
              </div>
            )}

            {/* expliciptly set button */}
            {button?.url && (
              <Button
                to={button?.url}
                color={colorScheme === "yellowWhite" ? "purple" : "white"}
                size="md"
              >
                <Text>{button?.text}</Text>
              </Button>
            )}

            {/* default category button */}
            {!button?.url && !entries?.length && (
              <Button
                to={`${hasCategory ? categories?.[0]?.url : "/articles"}`}
                color={colorScheme === "yellowWhite" ? "purple" : "white"}
                size="md"
              >
                <Text>View More</Text>
              </Button>
            )}
          </Container>
        </div>
      </Container>
    </section>
  );
};

LatestEntries.defaultProps = {
  colorScheme: "yellowWhite",
};

export default LatestEntries;
